@import '~antd/dist/antd.css';
@import 'style/base.scss';

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}