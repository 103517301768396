.summarizeBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}
.summaRow {
  display: flex;
  justify-content: center;
}
.summarizeBlock.dark {
  background-color: #F7F9FC;
  border-radius: 6px;
}

@media only screen and (max-width: 550px) {
  .summarizeBlock {
    gap: 20px;
  }
  .summaRow {
    width: 100%;
    background-color: #F7F9FC;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .summarizeBlock.dark {
      background-color: transparent;
      padding: 0;
  }
}