@import '../../style/base.scss';

.footerCTA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 580px;
  background-image: url(../../image/mockup.png);
  background-size: cover;
  background-color: $color-basic-1100;
  color: white;
}
.footerCTAContent {
  width: $app-max-width;
  padding: 30px 10px 80px 10px;
}
@media only screen and (max-width: $app-max-width) {
  .footerCTA {
    height: 200px;
  }
  .footerCTAContent {
    width: 100%;  
  }
}