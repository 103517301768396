.carBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.carMakeModelBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-grow: 1;
}
.carMakeModel {
  white-space: nowrap;
}
.badgeBlock{
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
}
.carMakeModelBlockCol .badgeBlock{
  margin-top: 6px;
}
.carDataBlock {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  gap: 20px;
  flex-grow: 1;
  margin-top: 10px;
}
@media only screen and (max-width: 550px) {
  .carDataBlock {
    margin-top: 20px;
  }
}