@import '../../../style/base.scss';

.storeCtaBlock {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  //height: 420px;
}
.storeCtaPhoneBlock {
  width: 210px;
  height: 440px;
}
.ctaPhone {
  height: 690px;
  margin-left: -205px;
  margin-top: -54px;
}

.storeCtaContent{
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
}
.ctaContentList {
  padding-left: 14px;
  margin: 0;
  font-family: $text-subtitle-2-font-family;
  font-size: $text-subtitle-2-font-size;
  font-weight: $text-subtitle-2-font-weight;
  line-height: $text-subtitle-2-line-height;
  color: $color-basic-100;
}
.imgFluid {
  width: 170px;
}
.storeBtn {
  width: 170px;
  margin-top: 15px;
}
.starBadge {
  position: relative;
  margin-top: 14px;
  border-radius: 4px;
  padding: 5px 8px 5px 46px;
  width: fit-content;
  background-color: white;
}
.starBadgeIcon {
  position: absolute;
  top: -11px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -6px;
  background-image: url('../../../image//star.svg');
  background-repeat: no-repeat;
  //background-position: left 20% top 10px;
  //background-size: 750px;
}
@media only screen and (max-width: $app-max-width) {
  .storeCtaContent {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1020px) {
  .storeCtaBlock {
    //flex: none;
    //height: 435px;
  }
}
@media only screen and (max-width: 510px) {
  .storeCtaPhoneBlock {
    width: 185px;
  }
}