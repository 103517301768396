@import '../../style/base.scss';

h1, h2, h3, h4, h5, h6 {
  //padding: 0;
  margin: 0;
  margin-bottom: 4px;
}
h1{
  font-family: $text-heading-1-font-family;
  font-size: $text-heading-1-font-size;
  font-weight: $text-heading-1-font-weight;
  line-height: $text-heading-1-line-height;
  color: $color-basic-800;
}
h2{
  font-family: $text-heading-2-font-family;
  font-size: $text-heading-2-font-size;
  font-weight: $text-heading-2-font-weight;
  line-height: $text-heading-2-line-height;
  color: $color-basic-800;
}
h3{
  font-family: $text-heading-3-font-family;
  font-size: $text-heading-3-font-size;
  font-weight: $text-heading-3-font-weight;
  line-height: $text-heading-3-line-height;
  color: $color-basic-800;
}
h4{
  font-family: $text-heading-4-font-family;
  font-size: $text-heading-4-font-size;
  font-weight: $text-heading-4-font-weight;
  line-height: $text-heading-4-line-height;
  color: $color-basic-800;
}
h5{
  font-family: $text-heading-5-font-family;
  font-size: $text-heading-5-font-size;
  font-weight: $text-heading-5-font-weight;
  line-height: $text-heading-5-line-height;
  color: $color-basic-800;
}
h6{
  font-family: $text-heading-6-font-family;
  font-size: $text-heading-6-font-size;
  font-weight: $text-heading-6-font-weight;
  line-height: $text-heading-6-line-height;
  color: $color-basic-800;
}
div.s1 {
  padding: 0;
  margin: 0;
  font-family: $text-subtitle-1-font-family;
  font-size: $text-subtitle-1-font-size;
  font-weight: $text-subtitle-1-font-weight;
  line-height: $text-subtitle-1-line-height;
  color: $color-basic-800;
}
div.s2 {
  padding: 0;
  margin: 0;
  font-family: $text-subtitle-2-font-family;
  font-size: $text-subtitle-2-font-size;
  font-weight: $text-subtitle-2-font-weight;
  line-height: $text-subtitle-2-line-height;
  color: $color-basic-800;
}
div.c1 {
  padding: 0;
  margin: 0;
  font-family: $text-caption-1-font-family;
  font-size: $text-caption-1-font-size;
  font-weight: $text-caption-1-font-weight;
  line-height: $text-caption-1-line-height;
  color: $color-basic-800;
}
div.c2 {
  padding: 0;
  margin: 0;
  font-family: $text-caption-2-font-family;
  font-size: $text-caption-2-font-size;
  font-weight: $text-caption-2-font-weight;
  line-height: $text-caption-2-line-height;
  color: $color-basic-800;
  text-align: center;
}
p.p1 {
  //padding: 0;
  //margin: 0;
  font-family: $text-paragraph-1-font-family;
  font-size: $text-paragraph-1-font-size;
  font-weight: $text-paragraph-1-font-weight;
  line-height: $text-paragraph-1-line-height;
  color: $color-basic-800;
}
p.p2 {
  //padding: 0;
  //margin: 0;
  font-family: $text-paragraph-2-font-family;
  font-size: $text-paragraph-2-font-size;
  font-weight: $text-paragraph-2-font-weight;
  line-height: $text-paragraph-2-line-height;
  color: $color-basic-800;
}
label {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: $text-label-font-family;
  font-size: $text-label-font-size;
  font-weight: $text-label-font-weight;
  line-height: $text-label-line-height;
  color: $color-basic-800;
}
h1.basic-100,
h2.basic-100,
h3.basic-100,
h4.basic-100,
h5.basic-100,
h6.basic-100,
div.s1.basic-100,
div.s2.basic-100,
p.p1.basic-100,
p.p2.basic-100,
div.c1.basic-100,
div.c2.basic-100,
label.basic-100 {
  color: $color-basic-100;
}

h1.primary-500,
h2.primary-500,
h3.primary-500,
h4.primary-500,
h5.primary-500,
h6.primary-500,
div.s1.primary-500,
div.s2.primary-500,
p.p1.primary-500,
p.p2.primary-500,
div.c1.primary-500,
div.c2.primary-500,
label.primary-500 {
  color: $color-primary-500;
}

@media only screen and (max-width: 750px) {
  h4 {
    font-size: $text-heading-4-font-size-mobile;
  }
  div.s2 {
    font-size: $text-subtitle-2-font-size-mobile;
  }
}