@import '../../../../style/base.scss';

.widgetBlock {
  background-color: white;
  padding: 20px 20px 30px 20px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.widgetPriceBox {
  border-radius: 4px;
  background-color: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.widgetPriceRange {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.widgetColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
  //min-width: 75px;
}
.pin {
  background-color: transparent;
  height: 20px;
  width: 6px;
}
.widgetSticker {
  border-radius: 8px;
  width: 100%;
  height: 8px;
  background-color: gray;
  margin-bottom: 4px;
}

.widgetBlock.low > .widgetPriceBox,
.widgetBlock.low > .widgetPriceRange > .widgetColumn.low > .pin {
  background-color: #FCCB64;
}
.widgetBlock.medium_low > .widgetPriceBox,
.widgetBlock.medium_low > .widgetPriceRange > .widgetColumn.medium_low > .pin {
  background-color: #51D68B;
}
.widgetBlock.medium > .widgetPriceBox,
.widgetBlock.medium > .widgetPriceRange > .widgetColumn.medium > .pin {
  background-color: #108667;
}
.widgetBlock.medium_high > .widgetPriceBox,
.widgetBlock.medium_high > .widgetPriceRange > .widgetColumn.medium_high > .pin {
  background-color: #51D68B;
}
.widgetBlock.high > .widgetPriceBox,
.widgetBlock.high > .widgetPriceRange > .widgetColumn.high > .pin {
  background-color: #FCCB64;
}

.widgetColumn.low > .widgetSticker{
  background-color: #FCCB64;
}
.widgetColumn.medium_low > .widgetSticker{
  background-color: #51D68B;
}
.widgetColumn.medium > .widgetSticker{
  background-color: #108667;
}
.widgetColumn.medium_high > .widgetSticker{
  background-color: #51D68B;
}
.widgetColumn.high > .widgetSticker{
  background-color: #FCCB64;
}

@media only screen and (max-width: 550px) {
  .widgetBlock {
    padding-bottom: 20px;
  }
}