@import '../../style/base.scss';

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-basic-1100;
  color: #FFFFFF;
  position: fixed;
  top: 0px;
  z-index: 100;
}
.topContent {
  width: $app-max-width;
  height: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  height: 30px;
  pointer-events: none;
  float: left;
}
.navigation {
  padding-left: 10px;
  float: right;
  display: flex;
  gap: 40px;
}
.hamburgerMenu {
  cursor: pointer;
  display: none;
}
.navigation.menuOpen {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  background-color: $color-basic-1100;
  padding: 20px;
}
@media only screen and (max-width: $app-max-width) {
  .topContent {
    width: 100%;  
  }
}

@media only screen and (max-width: 750px) {
  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px 0px 20px;
  }
  .navigation {
    display: none;
    flex-direction: column;
  }
  .logo {
    float: right;
  }
  .hamburgerMenu {
    display: block;
  }
}