@import '../../style/base.scss';

.headerBlue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #4149DF;
  margin-top: 60px;
  background-image: url('../../image/circle_blue.svg');
  background-repeat: no-repeat;
  background-position: left 20% top 10px;
  background-size: 750px;
}
.headerGreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #17A06E;
  margin-top: 60px;
  background-image: url('../../image/circle_green.svg');
  background-repeat: no-repeat;
  background-position: left 20% top 10px;
  background-size: 750px;
}
.headerContent {
  width: $app-max-width;
  padding: 30px 10px 0px 10px;
}
.headerRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 30px;
  flex-wrap: wrap;
}
@media only screen and (max-width: $app-max-width) {
  .headerContent {
    width: 100%;
    padding: 30px;
  }
}

@media only screen and (max-width: 960px) {
  .headerRow {
    gap: 20px;
  }
  #rowOne {
    flex-direction: column;
  }
}