@import '../../style/base.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-basic-1100;
  color: white;
}
.footerContent {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: $app-max-width;
  padding: 60px 10px 60px 10px;
  justify-content: space-between;
  align-items: center;
}

.footerLogo {
  height: 20px;
  pointer-events: none;
  float: left;
}
.facebookLogo {
  margin-right: 20px;
}
@media only screen and (max-width: $app-max-width) {
  .footerContent {
    width: 100%;  
  }
}
@media only screen and (max-width: 750px) {
  .footerContent {
    padding: 30px;
  }
}