@import './variable.scss';
@import './typography/font.scss';
@import './color.scss';

html, body {
  margin: 0;
  padding: 0;
  font-family: $text-font-family;
  //color: $primary-color;
  //line-height: 1.35;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  background-color: $color-basic-200;
  scroll-behavior: smooth;
}
.ant-tooltip-inner {
  padding: 20px;
}

.ant-btn.ant-btn-background-ghost:hover, 
.ant-btn.ant-btn-background-ghost:focus {
  border-color: white;
  color: white;
}