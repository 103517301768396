@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: url(./font/WorkSans-ExtraBold.ttf);
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url(./font/WorkSans-SemiBold.ttf);
}
@font-face {
  font-family: 'WorkSans-Bold';
  src: url(./font/WorkSans-Bold.ttf);
}
@font-face {
  font-family: 'WorkSans-Medium';
  src: url(./font/WorkSans-Medium.ttf);
}
@font-face {
  font-family: 'WorkSans-Regular';
  src: url(./font/WorkSans-Regular.ttf);
}

$text-font-family: "WorkSans-Medium";

$text-heading-1-font-size: 36px;
$text-heading-1-font-weight: 800;
$text-heading-1-font-family: "WorkSans-ExtraBold";
$text-heading-1-line-height: 36px;
$text-heading-2-font-size: 32px;
$text-heading-2-font-weight: 700;
$text-heading-2-font-family: "WorkSans-Bold";
$text-heading-2-line-height: 32px;
$text-heading-3-font-size: 30px;
$text-heading-3-font-weight: 600;
$text-heading-3-font-family: "WorkSans-SemiBold";
$text-heading-3-line-height: 40px;
$text-heading-4-font-size: 26px;
$text-heading-4-font-size-mobile: 21px;
$text-heading-4-font-weight: 600;
$text-heading-4-font-family: "WorkSans-SemiBold";
$text-heading-4-line-height: 32px;
$text-heading-5-font-size: 22px;
$text-heading-5-font-weight: 600;
$text-heading-5-font-family: "WorkSans-SemiBold";
$text-heading-5-line-height: 32px;
$text-heading-6-font-size: 18px;
$text-heading-6-font-weight: 600;
$text-heading-6-font-family: "WorkSans-SemiBold";
$text-heading-6-line-height: 24px;

$text-subtitle-1-font-size: 15px;
$text-subtitle-1-font-weight: 500;
$text-subtitle-1-font-family: "WorkSans-Medium";
$text-subtitle-1-line-height: 24px;
$text-subtitle-2-font-size: 13px;
$text-subtitle-2-font-size-mobile: 11px;
$text-subtitle-2-font-weight: 400;
$text-subtitle-2-font-family: "WorkSans-Regular";
$text-subtitle-2-line-height: 24px;


$text-paragraph-1-font-size: 14px;
$text-paragraph-1-font-weight: 400;
$text-paragraph-1-font-family: "WorkSans-Regular";
$text-paragraph-1-line-height: 22px;
$text-paragraph-2-font-size: 12px;
$text-paragraph-2-font-weight: 400;
$text-paragraph-2-font-family: "WorkSans-Regular";
$text-paragraph-2-line-height: 18px;

$text-caption-1-font-size: 12px;
$text-caption-1-font-weight: 400;
$text-caption-1-font-family: "WorkSans-Regular";
$text-caption-1-line-height: 16px;
$text-caption-2-font-size: 12px;
$text-caption-2-font-weight: 400;
$text-caption-2-font-family: "WorkSans-Regular";
$text-caption-2-line-height: 16px;

$text-label-font-size: 9px;
$text-label-font-weight: 400;
$text-label-font-family: "WorkSans-Regular";
$text-label-line-height: 16px;

//@media only screen and (max-width: 700px) {
//}

@media only screen and (max-width: 750px) {
  //$text-heading-4-font-size: $text-heading-4-font-size-mobile;
  //$text-subtitle-2-font-size: $text-subtitle-2-font-size-mobile;
}