.explanationBlock {
  display: flex;
  flex-grow: 3;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  background-color: white;
  padding: 40px;
  border-radius: 4px;
  margin-bottom: 60px;
}
.explanationContent {
  display: flex;
  flex-grow: 3;
  align-items: flex-start;
}
.explanationContentColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: min-content;
}

@media only screen and (max-width: 750px) {
  .explanationBlock {
    gap: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .explanationBlock {
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }
}