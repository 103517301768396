@import '../../style/base.scss';

.education {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-success-600;
}
.educationBlock {
  width: $app-max-width;
  flex-direction: column;
  padding-bottom: 60px;
}
.educationContent {
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.educationContent_left {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.educationContent_right {
  display: flex;
  flex-grow: 6;
  flex-direction: column;
}
.educationContent_right_top {
  display: flex;
  align-items: center;
}
.educationContent_right_bottom {
  padding-left: 44px;
}
.educationContent_right_bottom > div { 
  text-align: left;
}
.alertIcon {
  margin-right: 20px;
  height: 24px;
}
@media only screen and (max-width: $app-max-width) {
  .educationBlock {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .educationContent {
    flex-direction: column;
    padding: 30px;
    gap: 0;
  }
  .educationContent_right_top .alertIcon {
    display: none;
  }
  .educationContent_right_bottom {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .educationContent {
    flex-direction: column;
    gap: 0;
  }
  .educationInformation .alertIcon {
    display: none;
  }
  .educationContent_right_bottom {
    padding-left: 0px;
  }
}