@import '../../style/base.scss';

.badge {
  height: 24px;
  min-width: 70px;
  border-radius: 24px;
  display: inline-block;
  padding: 4px 16px 4px 16px;
  text-align: center;
  white-space: nowrap;
}

.badge.basic-300 {
  background-color: $color-basic-300;
}